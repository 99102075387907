import "aos/dist/aos.css"
import "./src/styles/app.scss"
import { GatsbyBrowser } from "gatsby"
import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer"

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  LogRocket.init(process.env.GATSBY_LOGROCKET_APP_ID!, {
    network: {
      isEnabled: true,
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().indexOf("gatsby") !== -1) {
          // ignore the request response pair
          return null
        }

        if (request.url.toLowerCase().indexOf("webpack") !== -1) {
          // ignore the request response pair
          return null
        }

        if (request.url.toLowerCase().indexOf("sentry") !== -1) {
          // ignore the request response pair
          return null
        }

        if (request.headers["authorization"]) {
          request.headers["authorization"] = ""
        }

        return request
      },
    },
  })
  setupLogRocketReact(LogRocket)
}
